<!--
 * @Auth: linjituan
 * @Date: 2021-12-30 17:20:46
 * @LastEditors: linjituan
 * @LastEditTime: 2022-11-17 11:03:16
-->
<template>
  <div class="many-vehicle-monitor">
    <vxe-modal
      v-model="manyVehicleMonitorVisible"
      width="730px"
      height="auto"
      title="监控"
      @close="handleCloseManyVehicleMonitor"
      show-zoom
      resize
      :lock-view="false"
      :mask="false"
      :esc-closable="true"
      :position="{ top: '10%', left: positionLeft }"
      remember
      transfer
      ref="manyVehicleMonitorRef"
    >
      <template v-if="objectPoints && objectPoints.length > 0">
        <div class="vehicle-monitor-container">
          <vehicle-monitor-item
            v-for="item in objectPoints.slice(0, 3)"
            :key="item.vehicleId"
            :vehicleItem="item"
            :taskStatusOptions="taskStatusOptions"
            @onVehicleMonitorEvent="handleVehicleMonitorEvent"
          />
          <baidu-rtc-player></baidu-rtc-player>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>
<script>
import VehicleMonitorItem from './modules/VehicleMonitorItem'
export default {
  props: {
    manyVehicleMonitorVisible: {
      type: Boolean,
      default: () => {}
    },
    objectPoints: {
      type: Array,
      default: () => {}
    },
    taskStatusOptions: {
      type: Array,
      default: () => {}
    }
  },
  components: { VehicleMonitorItem },
  data() {
    return {
      isDoing: false,
      positionLeft: document.body.clientWidth - 740
    }
  },
  mounted() {},
  beforeDestroy() {},
  watch: {
    manyVehicleMonitorVisible(newVal, oldVal) {},
    objectPoints(newVal, oldVal) {}
  },
  methods: {
    handleCloseManyVehicleMonitor() {
      this.$emit('onCloseManyVehicleMonitor')
    },

    handleVehicleMonitorEvent(eventName, eventArgs) {
      this.$emit('onVehicleMonitorEvent', eventName, eventArgs)
    }
  }
}
</script>
<style lang="less">
.many-vehicle-monitor {
  .vxe-modal--content {
    padding: 0;
  }
  .vxe-modal--wrapper .vxe-modal--content > div {
    // height: auto;
  }
  .vehicle-monitor-container {
    display: flex;
    flex-direction: column;
    .vehicle-monitor-item {
      flex: 1;
    }
  }
}
</style>
