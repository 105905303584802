var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "many-vehicle-monitor" },
    [
      _c(
        "vxe-modal",
        {
          ref: "manyVehicleMonitorRef",
          attrs: {
            width: "730px",
            height: "auto",
            title: "监控",
            "show-zoom": "",
            resize: "",
            "lock-view": false,
            mask: false,
            "esc-closable": true,
            position: { top: "10%", left: _vm.positionLeft },
            remember: "",
            transfer: "",
          },
          on: { close: _vm.handleCloseManyVehicleMonitor },
          model: {
            value: _vm.manyVehicleMonitorVisible,
            callback: function ($$v) {
              _vm.manyVehicleMonitorVisible = $$v
            },
            expression: "manyVehicleMonitorVisible",
          },
        },
        [
          _vm.objectPoints && _vm.objectPoints.length > 0
            ? [
                _c(
                  "div",
                  { staticClass: "vehicle-monitor-container" },
                  [
                    _vm._l(_vm.objectPoints.slice(0, 3), function (item) {
                      return _c("vehicle-monitor-item", {
                        key: item.vehicleId,
                        attrs: {
                          vehicleItem: item,
                          taskStatusOptions: _vm.taskStatusOptions,
                        },
                        on: {
                          onVehicleMonitorEvent: _vm.handleVehicleMonitorEvent,
                        },
                      })
                    }),
                    _c("baidu-rtc-player"),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }